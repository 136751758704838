export enum AppInsightsEvent {
	BootCompleted = "boot-completed",
	PwaInstalled = "pwa-installed",
	CustomBackendSettings = "custom-backend-settings",
	FatalError = "fatal-error",
	ModuleRequest = "module-request",
	ModuleDownloaded = "module-downloaded",
	ModuleSetupStart = "module-setup-start",
	ModuleSetupEnd = "module-setup-end",
	ModuleDispose = "module-dispose"
}
