import type { RouteRecordRaw } from "vue-router";

const baseRoutes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "home",
		component: () => import("../../views/home/home-comp.vue")
	},
	{
		path: "/login",
		name: "login",
		component: () => import("../../views/login/login.vue"),
		meta: {
			notShowing: true
		}
	},
	{
		path: "/download-certificate",
		name: "download-certificate",
		component: () => import("../../views/download-certificate/download-certificate.vue"),
		meta: {
			notShowing: true
		}
	}
];

export default baseRoutes;
