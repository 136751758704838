<template>
	<QModal
		id="modal-settings"
		v-model="modalOpenValue"
		:title="translateKey('set_custom_settings')"
		:no-close-on-backdrop="true"
		:cancel-disabled="isResetDisabled()"
		:cancel-title="translateKey('reset')"
		:ok-disabled="isSendDisabled()"
		ok-variant="primary"
		:ok-title="translateKey('send')"
		@close="onCloseSettings()"
		@click-cancel="resetCustomSettings()"
		@click-ok="sendCustomSettings()"
	>
		<div class="d-flex w-100 flex-column mb-3">
			<div class="row align-items-center mb-3">
				<label
					class="col-3 text-left"
					for="backendUrl"
				>
					{{ translateKey('backend_url') }}
				</label>
				<QFormFieldInput
					id="backendUrl"
					v-model="cloudUrl"
					class="col-9"
					name="backendUrl"
					:placeholder="translateKey('backend_url')"
					:hide-validation="true"
					autocomplete="off"
					rules="required"
				/>
			</div>
			<div class="row align-items-center mb-3">
				<label
					class="col-3 text-left"
					for="agent4DUrl"
				>
					{{ translateKey('agent4D_url') }}
				</label>
				<QFormFieldInput
					id="agent4DUrl"
					v-model="agent4DUrl"
					class="col-9"
					name="agent4DUrl"
					:placeholder="translateKey('agent4D_url')"
					:hide-validation="true"
					autocomplete="off"
					rules="required"
				/>
			</div>
			<div class="row align-items-center">
				<label
					class="col-3 text-left"
					for="systemId"
				>
					{{ translateKey('system_id') }}
				</label>
				<QFormFieldInput
					id="systemId"
					v-model="localSystemId"
					class="col-9"
					name="systemId"
					:placeholder="translateKey('system_id')"
					:hide-validation="true"
					autocomplete="off"
					rules="required"
				/>
			</div>
		</div>
	</QModal>
</template>
<script lang="ts" src="./q-settings.ts"></script>
