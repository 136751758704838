import { RemovableRef, useStorage } from "@vueuse/core";

type StorageData = {
	terminalAuthInfo: {
		AuthComplete?: boolean,
		CenterId: number,
		LanIpAddress: string
	}
}

export const terminalAuthInfo: RemovableRef<StorageData["terminalAuthInfo"]> = useStorage("terminalAuthInfo", {
	AuthComplete: false,
	CenterId: 0,
	LanIpAddress: ""
});
