import { createDsy } from "@qamf/lighthouse";
import { useObservability } from "@qamf/shell-app-sdk";
import { createPinia } from "pinia";
import { createApp, ref } from "vue";

import App from "./app.vue";
import boot from "./boot";
import router from "./router/index";
import { AppInsightsEvent } from "./utilities/observability-events";
export const appSettingsReady = ref(false);
const pinia = createPinia();
const app = createApp(App);
const dsy = createDsy();

window.addEventListener("load", async() => {
	await boot();
	app.use(pinia);
	app.use(router);
	app.use(dsy);
	appSettingsReady.value = true;
	const { trackEvent } = useObservability();
	trackEvent(AppInsightsEvent.BootCompleted);

	app.mount("#app");
});
