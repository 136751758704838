import { QFormFieldInput, QModal, useForm } from "@qamf/lighthouse";
import { use4DAgent, useAppStore, useAppStoreManager, useCloudBackend, useObservability } from "@qamf/shell-app-sdk";
import { computed, defineComponent, onBeforeMount, ref, toRaw } from "vue";

import { useTranslations } from "@/i18n/create-translations";

import { AppInsightsEvent } from "../../utilities/observability-events";
export default defineComponent({
	name: "QSettings",
	components: {
		QModal,
		QFormFieldInput
	},
	model: {
		prop: "modalOpen",
		event: "update:modalOpen"
	},
	props: {
		modalOpen: { type: Boolean }
	},
	emits: ["update:modalOpen"],
	setup(props, { emit }) {
		const { setOption: setCloudOption, getOption: getCloudOption } = useCloudBackend();
		const { setOption: setAgent4DOptions, getOption: getAgent4DOptions } = use4DAgent();
		const { trackEvent } = useObservability();
		const { translateKey } = useTranslations();
		const { systemId: storeSystemId } = useAppStore();
		const { setSystemId: setStoreSystemId } = useAppStoreManager();
		const { isFieldDirty, errors, values } = useForm();

		const cloudUrl = ref(getCloudOption("host"));
		const agent4DUrl = ref(getAgent4DOptions("host"));
		const localSystemId = ref(storeSystemId.value);

		const initialValues = ref({
			cloudHost: getCloudOption("host"),
			agent4DHost: getAgent4DOptions("host")
		});

		const modalOpenValue = computed({
			get: () => props.modalOpen,
			set: (value) => emit("update:modalOpen", value)
		});

		const formFieldNames = () => [...Object.keys(toRaw(values))];
		const areFieldsDirty = () => formFieldNames().reduce((a, f) => a || isFieldDirty(f), false);
		const formHasErrors = () => !!Object.keys(errors.value).length;
		const isSendDisabled = () => (!areFieldsDirty() || formHasErrors());
		const isResetDisabled = () => formHasErrors();

		const sendCustomSettings = () => {
			if (cloudUrl.value)
				setCloudOption("host", cloudUrl.value);

			if (agent4DUrl.value)
				setAgent4DOptions("host", agent4DUrl.value);

			if (localSystemId.value)
				setStoreSystemId(localSystemId.value);

			trackEvent(AppInsightsEvent.CustomBackendSettings, {
				mode: "edit",
				...(cloudUrl.value && { cloudUrl: cloudUrl.value }),
				...(agent4DUrl.value && { agent4DUrl: agent4DUrl.value })
			});

			modalOpenValue.value = false;
		};

		const resetCustomSettings = () => {
			cloudUrl.value = initialValues.value.cloudHost;
			agent4DUrl.value = initialValues.value.agent4DHost;

			localSystemId.value = storeSystemId.value;

			trackEvent(AppInsightsEvent.CustomBackendSettings, {
				mode: "reset",
				...(cloudUrl.value && { cloudUrl: cloudUrl.value }),
				...(agent4DUrl.value && { agent4DUrl: agent4DUrl.value })
			});

			modalOpenValue.value = false;
		};

		const onCloseSettings = () => {
			if (cloudUrl.value === "" || !cloudUrl.value)
				cloudUrl.value = getCloudOption("host");
			if (agent4DUrl.value === "" || !agent4DUrl.value)
				agent4DUrl.value = getAgent4DOptions("host");
			if (!localSystemId.value)
				localSystemId.value = storeSystemId.value;
		};

		onBeforeMount(() => {
			cloudUrl.value = getCloudOption("host");
			agent4DUrl.value = getAgent4DOptions("host");
			localSystemId.value = storeSystemId.value;
		});

		return {
			cloudUrl,
			agent4DUrl,
			localSystemId,
			sendCustomSettings,
			resetCustomSettings,
			isSendDisabled,
			isResetDisabled,
			modalOpenValue,
			onCloseSettings,
			translateKey
		};
	}
});
