import { QModal, QModalProps, useModalController } from "@qamf/lighthouse";
import { Component, defineComponent, h, VNode } from "vue";

import { useTranslations } from "@/i18n/create-translations";

export type ModalController = ReturnType<typeof useModalController>;

let _modalController: ModalController | null = null;

export function initModalUtil(controller: ModalController) {
	_modalController = controller;
}

function getDefaultOptions(): QModalProps {
	const { translateKey } = useTranslations();
	return {
		okTitle: translateKey("ok"),
		cancelTitle: translateKey("cancel"),
		cancelVariant: "outline-primary",
		okVariant: "primary",
		noCloseOnBackdrop: true,
		noCloseOnEsc: true,
		scrollable: true,
		centered: true,
		modalClass: "modal-utility",
		dialogClass: "",
		bodyClass: "",
		footerClass: "",
		modelValue: true
	};
}

function createModal(message: string | VNode[], localOptions: QModalProps) {
	return defineComponent({
		setup() {
			return () => h(
				QModal,
				{
					...localOptions as any
				},
				{
					default: () => message
				}
			);
		}
	});
}

async function launchModal(modalTemplate: Readonly<Component>) {
	if (_modalController === null) return;
	const modal = await _modalController.confirm?.({
		component: modalTemplate as any
	});
	location.reload();
	return modal;
}

export function showModalFatal(title: string, description: string) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, {});
	localOptions.modalClass = "modal-fatal";
	localOptions.hideFooter = true;
	const modalTemplate = createModal(description, {
		title,
		...localOptions
	});
	return launchModal(modalTemplate);
}
