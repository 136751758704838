import { createTranslations, useAppStoreManager } from "@qamf/shell-app-sdk";
import { ITranslation } from "@qamf/xliff-loader";

import Languages from "./languages.json" assert { type: "json" };
type UseTranslations = ReturnType<typeof createTranslations>;
let _useTranslations: UseTranslations = () => { throw new Error("useTranslations not initialized") };

export async function initMultiLanguage() {
	const useTranslations = createTranslations();
	const { setup: setupI18n, setLanguage } = useTranslations();
	setupI18n<ITranslation>({
		languages: Languages,
		languageFetcher(langCode) {
			console.log(`[i18n] Setting lang code: ${langCode}`);
			switch (langCode) {
				case "de-DE":
					return import("@qamf/qst-mod-neoverse/de-DE/neoverse.xlf");
				case "fr-FR":
					return import("@qamf/qst-mod-neoverse/fr-FR/neoverse.xlf");
				case "it-IT":
					return import("@qamf/qst-mod-neoverse/it-IT/neoverse.xlf");
				case "es-ES":
					return import("@qamf/qst-mod-neoverse/es-ES/neoverse.xlf");
				case "nb-NO":
					return import("@qamf/qst-mod-neoverse/nb-NO/neoverse.xlf");
				case "sv-SE":
					return import("@qamf/qst-mod-neoverse/sv-SE/neoverse.xlf");
				case "da-DK":
					return import("@qamf/qst-mod-neoverse/da-DK/neoverse.xlf");
				case "id-ID":
					return import("@qamf/qst-mod-neoverse/id-ID/neoverse.xlf");
				case "nl-NL":
					return import("@qamf/qst-mod-neoverse/nl-NL/neoverse.xlf");
				case "ja-JP":
					return import("@qamf/qst-mod-neoverse/ja-JP/neoverse.xlf");
				default:
					return import("./locale.xlf");
			}
		},
		translateKeyResolver(repo, key) {
			if (!repo) {
				console.warn(`[i18n] The Translation-Repository is not defined. Returning the key as is: ${key}`);
				return key;
			}
			let translateString = "";
			if (key in repo.target && repo.target[key])
				translateString = repo.target[key].trim();
			else if (key in repo.source && repo.source[key])
				translateString = repo.source[key].trim();
			else {
				console.warn(`[i18n] The Translation-Key '${key}' was not translated`);
				translateString = `[${key.toUpperCase()}]`;
			}
			if (!translateString)
				console.warn(`[i18n] The Translation-Key '${key}' is empty.`);
			return translateString;
		}
	});
	const { setLangIsoCode } = useAppStoreManager();
	setLangIsoCode("en-US");
	await setLanguage("en-US");
	_useTranslations = useTranslations;
}
export const useTranslations = () => _useTranslations();
