import { useObservability } from "@qamf/shell-app-sdk";
import { createRouter, createWebHistory } from "vue-router";

import { terminalAuthInfo } from "@/utilities/persistentStorage";

import { appSettingsReady } from "../main";
import baseRoutes from "./routes/base";

const router = createRouter({
	history: createWebHistory("/"),
	linkActiveClass: "active",
	routes: baseRoutes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			};
		}
		return { x: 0, y: 0 };
	}
});

router.beforeEach((to) => {
	const isAuthComplete = terminalAuthInfo.value.AuthComplete;

	if (to.matched.some(r => r.meta.requiresAuth) && !isAuthComplete)
		return { name: "login" };

	if (!to.name)
		return false;

	if (appSettingsReady.value) {
		const { trackView } = useObservability();
		trackView(to.name.toString(), "start", to.fullPath);
	}

	return true;
});

router.afterEach(to => {
	if (appSettingsReady.value && to.name) {
		const { trackView } = useObservability();
		trackView(to.name.toString(), "end", to.fullPath);
	}
});

export function useRouter() {
	return router;
}

export const useRoute = () => {
	return useRouter().currentRoute;
};

export default router;
