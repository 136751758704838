import EventEmitter, { type OnOptions } from "eventemitter2";

import { AppInsightsEvent } from "../utilities/observability-events";
import type { IErrorEventHandler } from "./events.definition";

export type EventManager = {
	emitError(error: Parameters<IErrorEventHandler>[0], extra?: Parameters<IErrorEventHandler>[1]): void,
	onError(handler: IErrorEventHandler, onOptions?: OnOptions): void
}

let _eventBus: EventEmitter;

export default function useEventsManager(): EventManager {
	if (!_eventBus)
		_eventBus = new EventEmitter();

	return {
		emitError(error, extra?) {
			return _eventBus.emit(AppInsightsEvent.FatalError, error, extra);
		},
		onError(handler, onOptions?) {
			const listener = _eventBus.on(AppInsightsEvent.FatalError, handler, onOptions);
			return () => {
				listener.off(AppInsightsEvent.FatalError, handler);
			};
		}
	};
}
